<template>
  <div v-if="isLoaded" className="animated">
    <h3>
      {{ participant.fullName }} 
      <b-btn
        v-if="isDirector"
        size="sm"
        class="pull-right"
        variant="warning"
        :to="{ name: 'participantForm', query: { pid: participant.id } }"
      ><i class="fa fa-pencil"></i> Edit Participant</b-btn>
    </h3>
    <p> ({{ age }} y/o {{ participant.gender }})</p>
    <hr />
    <!-- PARTICIPANT RELATIONSHIPS -->
    <b-card title="Member Relationships">
      <b-card v-for="pr in participantRalationships" :key="pr.id">
        <div class="m-0">
          <b-row>
            <b-col cols="12" md="3"><h5><b>Member</b></h5></b-col>
            <b-col><h5><router-link :to="{ name: 'userForm', query: { uid:pr.user.id  } }">{{ pr.user.fullName }}</router-link></h5></b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="3"><b>Relationship</b></b-col>
            <b-col>{{ pr.relationship.label }} <small v-if="pr.primaryContact">(<b>Primary</b>)</small></b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="3"><b>Phone</b></b-col>
            <b-col><a :href="'tel:+1' + pr.user.phone.replace(/[^0-9]/g, '')">{{ pr.user.phone }}</a></b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="3"><b>Email</b></b-col>
            <b-col>
              <a :href="'mailto:' + pr.user.email">{{ pr.user.email }}</a>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4 text-right">
          <b-button-group size="sm" class="mx-1">
            <b-button @click="onMessageUser(pr.user.id)"><i class="fa fa-envelope"></i> Message</b-button>
            <b-button v-if="isDirector" variant="warning" :to="{ name: 'userForm', query: { uid: pr.user.id }}"><i class="fa fa-pencil"></i> Edit Member</b-button>
          </b-button-group>
        </div>
      </b-card>
    </b-card>
    
    <!-- EVENT REGISTRATIONS -->
    <b-card v-if="this.currentBusiness.id" title="Event Registrations">
      <div v-for="registration in registrations" :key="registration.id">
        <router-link :class="{
          'text-danger': !registration.invoice.transactionId
        }" :to="{ name: 'eventRegistrationDetails', params: { rid: registration.id } }">{{ registration.event.title }}</router-link>
      </div>
      <hr v-if="registrations.length" />
      <p class="text-info">Note: Only the events that this participant is elegable for display here.</p>
      <b-input-group>
        <b-form-select
          :options="eventOptions"
          value-field="@id"
          text-field="title"
          v-model="event"
        ></b-form-select>
        <b-input-group-append>
          <b-button @click.prevent="registerParticipantForEvent" :disabled="!event" variant="success">Register</b-button>
        </b-input-group-append>
      </b-input-group>
	  </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ParticipantDetails',
  props: {
    pid: Number
  },
  data () {
    return {
      isLoaded: false,
      participant: {},
      event: null,
      registrations: []
    }
  },
  computed: {
    ...mapGetters([
      'participantRalationships',
      'events'
    ]),
    eventOptions () {
      let eventOptions = this.events.filter((value) => {
	        return ( this.age >= value.ageMin && this.age <= value.ageMax && value.status.id === 2 )
	    })
      eventOptions.push({'@id': null, title: 'Select an event for registration', disabled: true})
      return eventOptions
    },
    primaryContact () {
      for (let i in this.participantRalationships) {
        if (this.participantRalationships[i].primaryContact) {
          return this.participantRalationships[i].user.id
        }
      }
    },
    age () {
      return this.$moment().diff(this.participant.dob, 'years')
    },
    participantData () {
      let participantData = {}
      for (let i in this.participantRalationships) {
        participantData[this.participantRalationships[i].relationship.label] = this.participantRalationships[i].user.fullName +
        (this.participantRalationships[i].primaryContact ? ' (Primary Contact)' : '')
      }
      return [participantData]
    }
  },
  methods: {
    ...mapActions([
      'getParticipant',
      'getParticipantRelationships',
      'resetParticipantRelationships',
      'getEvents',
      'getEventRegistrations',
      'getEventGroups'
    ]),
    registerParticipantForEvent () {
      let eid = this.event.split('/')
      this.$router.push({ 
        name: 'eventRegistrationForm', 
        query: {
          pid: this.participant.id,
          mid: this.participantRalationships[0].user.id,
          eid: eid[eid.length-1]
        }
      })
    },
    doGetEventRegistrations () {
      this.getEventRegistrations({
        business: this.currentBusiness.id,
        'participant.id': this.participant.id
      }).then(resp => {
        this.registrations = resp
      })
    },
    onMessageUser (uid){
      this.$router.push({ name: 'Compose', params: { uid: uid } })
    }
  },
  created () {
    let pid = this.pid
    if (this.$route.query.pid) {
      pid = this.$route.query.pid
    }
    if (this.$route.params.pid) {
      pid = this.$route.params.pid
    }
    let params = {
      id: pid
    }
    if (this.isCoach || this.isCoachJr) {
      this.getEventGroups({
        coach: this.currentUser.id,
        participant: this.$route.params.pid
      }).then(resp => {
        // console.log(resp.length)
      })
    }
    if (this.isDirector) {
      params.businesses = {}
      params.businesses.id = this.currentBusiness.id
    }
    if (pid) {
	    this.getParticipant(params).then(resp => {
	      this.participant = resp
        this.isLoaded = true
        this.doGetEventRegistrations()
	      this.getEvents({
          business: this.currentBusiness.id,
	        status: [1,2,3]
	      })
	    })
	    this.getParticipantRelationships({
	      participant: pid
	    })
    }
  },
  destroyed () {
    this.resetParticipantRelationships()
  }
}
</script>